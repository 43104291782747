import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import './index.css';

function App() {
  return (
    <Router>
      <div style={{ height: '100vh', backgroundColor: '#000' }} className='overflow-y-auto'>
        <div className='flex flex-row fixed z-50 justify-between w-full'>
          <div className='assetfest_logo text-slate-100/90 font-bold text-3xl p-5 z-20'>

            <Link to="/" className='bg-black'>assetfest</Link>
          </div>
          <div className='text-white pt-7 pr-3 flex font-medium'>
            <Link to="/" className='px-2 '><span className='bg-black'>Home</span></Link>
            {/* <Link to="/products" className='px-2'><span className='bg-black'>Products</span></Link> */}
            <Link to="/about" className='px-2'><span className='bg-black'>About</span></Link>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={
            <div className='section w-full h-[100vh] flex justify-center items-center relative z-10'>
              <h1 className='text-white/80 text-6xl md:text-7xl pl-3 md:pl-0 font-bold'>Products Page</h1>
            </div>
          } />
          <Route path="/about" element={
            <div className='w-full flex justify-center items-center relative z-10'>
              <div>
                <div className='flex flex-col md:flex-row w-full max-w-screen-md m-auto mt-[100px] md:mt-[150px]'>
                  <div className='w-full md:w-[50%] p-10'>
                    <div className='border-l-2 border-white/80 pl-8'>
                      <h1 className='text-white/80 text-5xl md:text-6xl font-bold'>about</h1>
                      <div className='text-white/80 text-xl'>the apparel industry.</div>
                    </div>
                    <div className='text-white/60 text-xl mt-6 leading-8'>

                      There isn't a single country where people do not wear clothes. To keep up with the
                      demand, the fashion industry produces over 100 billion garments a year. Little overshot,
                      don't you think? Today, the apparel industry is the second largest polluter in the world. And
                      you don't even want to know about the labor conditions.
                    </div>
                  </div>
                  <div className='w-full md:w-[50%] p-10'>
                    <div className='border-l-2 border-white/80 pl-8'>
                      <div className='text-white/80 text-5xl md:text-6xl font-bold'>us</div>
                      <div className='text-white/80 text-xl'>the don't-peril industry.</div>
                    </div>
                    <div className='text-white/60 text-xl mt-6 leading-8'>

                      We believe there’s untapped profit in sustainability and that we are rapidly converging on solutions that integrate environmental and economic interests.
                      We're a team built on passion who are excited to make the cutting edge solutions available to the world's most progressive clothing brands.
                      Through better use of the textiles that have already been made to reinventing the way we make
                      new textiles using technologies like on-demand manufacturing, unweaving, and researching sustainable materials, we're here to power the future of clothing.

                    </div>

                  </div>

                </div>
                <div className='m-auto w-[300px] font-mono mt-[50px] border-l-2 border-white pl-8'>
                  <div className='text-white/80 leading-8'>
                    <div className='text-3xl font-sans font-bold'>contact</div>
                    <div>Ari Katz</div>
                    <div>arikatz@berkeley.edu</div>
                    <div>+1 415 465 9837</div>
                  </div>

                </div>
                <br /><br /><br /><br /><br /><br />
              </div>

            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;