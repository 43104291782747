import React, { useRef, useState } from 'react';
import { Element } from 'react-scroll';
import ThreeDBackground from './3DBackground';
import TeamBackground from './teamBackground';
import UpAgainst from './upAgainst';

function Home() {
  const scrollContainerRef = useRef(null);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const handleScroll = (event) => {
    const scrollContainer = scrollContainerRef.current;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      // Reached the end, scroll back to the beginning
      console.log('Reached the end');
      scrollContainer.scrollTop = 1;
    } else if (scrollTop <= 0) {
      console.log('Reached the top');
      // Reached the top, scroll to the end
      scrollContainer.scrollTop = scrollHeight - clientHeight - 1;
    }

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setScrollTimeout(setTimeout(() => {
      scrollToNearestSection();
    }, 300));
  };

  const scrollToNearestSection = () => {
    const scrollContainer = scrollContainerRef.current;
    const sections = scrollContainer.querySelectorAll('.section');
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;

    let nearestSection = sections[0];
    let minDistance = Math.abs(sections[0].offsetTop - scrollTop);

    sections.forEach((section) => {
      const distance = Math.abs(section.offsetTop - scrollTop);
      if (distance < minDistance) {
        nearestSection = section;
        minDistance = distance;
      }
    });

    const sectionIndex = Array.from(sections).indexOf(nearestSection);
    const offset = sectionIndex === 0 ? 2 : sectionIndex === sections.length - 1 ? -2 : 0;

    scrollContainer.scrollTo({
      top: nearestSection.offsetTop + offset,
      behavior: 'smooth'
    });
  };

  return (
    <div
      ref={scrollContainerRef}
      style={{ overflowY: 'scroll', overflowX: 'none', height: '100vh', backgroundColor: '#000' }}
      className='overscroll-none w-full overflow-x-clip'
      onScroll={handleScroll}
    >
      <Element name='section0' className='section w-full h-[100vh] flex justify-center items-center relative z-10'>
        <TeamBackground />
        <h1 className='text-white/80 text-6xl md:text-7xl pl-3 md:pl-0 font-bold'>Work together instead.</h1>
      </Element>

      <Element name='section1' className='section w-full h-[100vh] flex justify-center items-center relative z-10'>
        <ThreeDBackground />
        <div className='text-white/80 text-6xl md:text-7xl pl-3 md:pl-0 font-bold mt-[200px]'>
          This is your brand.
          <h3 className='font-light text-xl text-center mt-[200px] italic'></h3>
        </div>
      </Element>

      <Element name='section2' className='section w-full h-[100vh] flex justify-center items-center relative z-10'>
        <UpAgainst />
        <div className='text-white/80 text-6xl md:text-7xl pl-3 md:pl-0 font-bold mt-[200px]'>
          This is your competition.
          <h3 className='font-light text-xl text-center mt-[200px] italic'></h3>
        </div>
      </Element>

      <Element name='section3' className='section w-full h-[100vh] flex justify-center items-center relative z-10'>
        <TeamBackground />
        <h1 className='text-white/80 text-6xl md:text-7xl pl-3 md:pl-0  font-bold'>Work together instead.</h1>
      </Element>
    </div>
  );
}

export default Home;