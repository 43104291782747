import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const TeamBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry();
    const edges = new THREE.EdgesGeometry(geometry);
    const material = new THREE.LineBasicMaterial({ color: 0xffffff }); // Set color to white

    const cubes = []; // Array to store the boxes

    const numBoxes = 10; // Number of boxes to create

    for (let i = 0; i < numBoxes; i++) {
      const cube = new THREE.LineSegments(edges, material);
      cubes.push(cube); // Add the cube to the array

      scene.add(cube);

      const initialScale = i; // Generate a random scale between 1 and 6
      cube.scale.set(initialScale, initialScale, initialScale); // Set the initial scale of each cube
    }

    camera.position.z = 15;

    const rotationSpeed = 0.005; // Set the rotation speed (adjust this value to make it spin slower)

    const animate = () => {
      requestAnimationFrame(animate);

      cubes.forEach((cube) => {
        cube.rotation.x += rotationSpeed;
        cube.rotation.y += rotationSpeed;
      });

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} className="absolute top-0 left-0 w-full h-full -z-10" />;
};

export default TeamBackground;
