import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const ThreeDBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry();
    const edges = new THREE.EdgesGeometry(geometry);
    const material = new THREE.LineBasicMaterial({ color: 0xffffff }); // Set color to white
    const cube = new THREE.LineSegments(edges, material);

    scene.add(cube);
    camera.position.z = 5;

    const initialScale = 10; // Set the initial scale of the cube to a larger value
    cube.scale.set(initialScale, initialScale, initialScale); // Set the initial scale of the cube

    const targetScale = 2; // Set the target scale of the cube
    let scaleStep = 0.01; // Set the scaling step

    const rotationSpeed = 0.005; // Set the rotation speed (adjust this value to make it spin slower)

    const animate = () => {
      requestAnimationFrame(animate);
      cube.rotation.x += rotationSpeed;
      cube.rotation.y += rotationSpeed;
      scaleStep -= 0.00003;

      // Ease in the cube gradually
      if (cube.scale.x > targetScale) {
        cube.scale.multiplyScalar(1 - scaleStep);
      }

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} className="absolute top-0 left-0 w-full h-full -z-10" />;
};

export default ThreeDBackground;