import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const UpAgainst = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry();
    const edges = new THREE.EdgesGeometry(geometry);
    const material = new THREE.LineBasicMaterial({ color: 0xffffff }); // Set color to white

    const cube1 = new THREE.LineSegments(edges, material);
    const cube2 = new THREE.LineSegments(edges, material);

    scene.add(cube1);
    scene.add(cube2);

    camera.position.z = 10;

    const rotationSpeed = 0.005; // Set the rotation speed (adjust this value to make it spin slower)

    cube1.position.x = -1.0; // Position the first cube to the left
    cube1.position.z = 9;
    cube2.position.x = 0; // Position the second cube to the right
    cube2.scale.set(9, 9, 9); // Make cube2 twice as big as cube1
    cube1.scale.set(0.05, 0.05, 0.05); // Make cube2 twice as big as cube1

    const animate = () => {
      requestAnimationFrame(animate);
      cube1.rotation.x += rotationSpeed;
      cube1.rotation.y += rotationSpeed;
      cube2.rotation.x += rotationSpeed;
      cube2.rotation.y += rotationSpeed;

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} className="absolute top-0 left-0 w-full h-full -z-10" />;
};

export default UpAgainst;
